<template>
  <div class="print-liquidacion">
    <div class="pos-liquidacion text-center mt-2" v-if="header">
      <div class="header">
        <h2>
          {{ header.razon }}
        </h2>
        <p>
          Nit: {{ header.nit }}
          <br />
          Dirección: {{ header.direccion }}
          <br />
          <strong>Periodo liquidación</strong>
          <br />
          <strong>Desde: </strong>
          {{ moment($route.params.inicio).format("ll") }}
          <br />
          <strong>Hasta: </strong> {{ moment($route.params.fin).format("ll") }}
        </p>
      </div>
      <div class="body">
        <table>
          <tbody>
            <tr>
              <th>Profesional:</th>
              <td>{{ profesional.nombre }}</td>
            </tr>
            <tr>
              <th>Cc:</th>
              <td>{{ profesional.cedula }}</td>
            </tr>
          </tbody>
        </table>
        <h4>Servicios realizados</h4>
        <table>
          <thead>
            <tr>
              <th>Fecha</th>
              <th>Cant</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in ventas" :key="index">
              <td style="text-align: left !important">
                {{ item.fecha }}
              </td>
              <td style="text-align: center !important">
                {{ item.servicios }}
              </td>
              <td>
                {{ item.total | currency }}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th>Total</th>
              <th style="text-align: center !important">
                {{ total_ventas.servicios }}
              </th>
              <th style="text-align: right !important">
                {{ total_ventas.total_servicios | currency }}
              </th>
            </tr>
          </tfoot>
        </table>
        <h4 v-if="params_data.productos.length > 0">Productos vendidos</h4>
        <table v-if="params_data.productos.length > 0">
          <thead>
            <tr>
              <th>Produ</th>
              <th style="text-align: center !important">%</th>
              <th>Comisión</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in params_data.productos" :key="index">
              <td style="text-align: left !important">{{ item.producto }}</td>
              <td style="text-align: center !important">
                {{ item.porcentaje }}%
              </td>
              <td>{{ item.comision | currency }}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th colspan="2">Total</th>
              <th style="text-align: right !important">
                {{ params_data.comision | currency }}
              </th>
            </tr>
          </tfoot>
        </table>
        <h4>Totalización</h4>
        <table>
          <tbody>
            <tr>
              <th>Total comisión servicios</th>
              <td>{{ total_ventas.total_servicios | currency }}</td>
            </tr>
            <tr>
              <th>Total comisión productos</th>
              <td>{{ params_data.comision | currency }}</td>
            </tr>
            <tr>
              <th>Total propina</th>
              <td>{{ parseFloat($route.params.propina) | currency }}</td>
            </tr>
            <tr>
              <th>Impuesto datafono</th>
              <td>
                {{ params_data.impuesto.porcentaje }}% |
                {{ params_data.impuesto.valor | currency }}
              </td>
            </tr>
            <tr>
              <th>Deducciones</th>
              <td>{{ parseFloat($route.params.prestamos) | currency }}</td>
            </tr>
            <tr>
              <th>Deducciones por abonos</th>
              <td>{{ parseFloat($route.params.deduccion) | currency }}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th>Total ingresos netos</th>
              <th style="text-align: right !important">
                {{
                  (total_ventas.total_servicios +
                    parseFloat(params_data.comision) -
                    parseFloat(params_data.impuesto.valor) +
                    parseFloat($route.params.propina) -
                    parseFloat($route.params.prestamos) -
                    parseFloat($route.params.deduccion))
                    | currency
                }}
              </th>
            </tr>
          </tfoot>
        </table>
        <h4 style="text-align: left !important; opacity: 0.8;">
          Quién autoriza
        </h4>
        <hr style="margin-top: 25px" />
        <h4 style="text-align: left !important; opacity: 0.8;">
          Recibo a conformidad
        </h4>
        <hr style="margin-top: 25px" />
      </div>
    </div>
    <v-row>
      <v-col>
        <v-btn dark color="black" class="ml-2 d-print-none" @click="print()"
          >Imprimir</v-btn
        >
        <v-btn color="primary" class="ml-2 d-print-none" @click="close()"
          >Cerrar</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>
<script>
import * as moment from "moment";
moment.locale("es");
export default {
  data: () => ({
    profesional: null,
    ventas: [],
    params_data: null,
    header: null,
  }),
  methods: {
    loadProfesionales() {
      const body = {
        route: "/profesionales_sede",
      };
      this.$store
        .dispatch("axios_get", body)
        .then((response) => {
          if (response.data.success) {
            let index = response.data.data.findIndex(
              (element) => element.id == this.$route.params.profesional_id
            );
            this.profesional = response.data.data[index];
          }
        })
        .finally(() => {
          this.loadLiquidacion();
        });
    },
    loadLiquidacion() {
      const body = {
        route: "/liquidacion_profesional",
        params: {
          id: this.profesional.id,
          date: JSON.stringify({
            inicio: this.$route.params.inicio,
            fin: this.$route.params.fin,
          }),
        },
      };

      this.$store.dispatch("get_data", body).then((response) => {
        if (response.data.success) {
          this.ventas = response.data.data.ventas.map((venta) => {
            venta.servicios = parseFloat(venta.servicios);
            venta.total = parseFloat(venta.total);
            return venta;
          });
          this.header = response.data.data.header;
        }
      });
    },
    moment(input) {
      return moment(input);
    },
    close() {
      window.close();
    },
    print() {
      window.print();
    },
  },
  created() {
    this.loadProfesionales();
  },
  mounted() {
    this.params_data = JSON.parse(atob(this.$route.params.data));
  },
  computed: {
    total_ventas() {
      return {
        servicios: this.ventas.reduce((index, item) => {
          return index + item.servicios;
        }, 0),
        total_servicios: this.ventas.reduce((index, item) => {
          return index + item.total;
        }, 0),
      };
    },
  },
};
</script>
<style scoped>
.pos-liquidacion {
  font-family: Arial;
  max-width: 80mm;
  margin: 0 auto;
  background: white;
  line-height: normal !important;
  color: black !important;
}
.header {
  font-weight: bold;
}

.header p {
  font-size: 14px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

table {
  width: 100%;
  padding: 8px;
}
table thead th,
tfoot th {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}
table td,
th {
  font-size: 14px !important;
}

table th {
  text-align: left !important;
}
table td {
  text-align: right !important;
}
</style>
